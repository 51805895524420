import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import BackImage from "../images/main-alt.jpg";
import { blueDeep, blueMedium } from "../utils/colors";
import QuestionsComponent from "../components/questions";

function MainFeaturedPost(props) {
  const { post } = props;

  return (
    <div
      sx={{
        position: "relative",
        color: blueMedium,
      }}
      style={{ marginTop: 30 }}
    >
      <Grid container alignItems={"center"}>
        <Grid item md={6}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
              style={{
                color: blueDeep,
                fontSize: "2.2em",
                fontWeight: 700,
                marginBottom: 35,
              }}
            >
              Le décret tertiaire : une nouvelle obligation reglementaire
            </Typography>
            <Typography
              variant="h5"
              style={{ color: blueMedium, fontSize: 19, paddingRight: 30 }}
              paragraph
            >
              Le décret tertiaire dont la première <b> obligation</b> entre en
              vigueur cette année définit les conditions d’application de la loi
              ELAN avec pour objectif d’agir pour une baisse de la consommation
              énergétique des bâtiments à usage tertiaire.
            </Typography>
            <QuestionsComponent
              questions={[
                {
                  title:
                    "Que dois-je faire dans le cadre du décret tertiaire ?",
                  link: "qui",
                },
                {
                  title: "Quelles en sont les étapes importantes ?",
                  link: "Echeances",
                },
                {
                  title: "Quels sont les enjeux du décret tertiaire ?",
                  link: "Risques",
                },
                {
                  title: "Quels sont vos leviers d’action ?",
                  link: "levier",
                },
                {
                  title: "En quoi Agrid peut-il m'accompagner ?",
                  link: "agrid",
                },
              ]}
            />{" "}
            <Typography
              style={{
                color: blueMedium,
                opacity: 0.6,
                fontSize: 12,
                marginTop: 30,
              }}
            >
              Mis à jour en septembre 2022
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={BackImage}
              style={{
                objectFit: "contain",
                width: "100%",
                padding: 20,
                maxHeight: 600,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;
