import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { LinkedIn } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import Main from "./Main";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { blueDeep, blueMedium, orange } from "../utils/colors";

const mainFeaturedPost = {
  title: "Qu'est-ce que le décret tertiaire ?",
  subtitle: "Comment l'appliquer",
  description:
    "Mieux comprendre les tenants et aboutissants du décret tertiaire, tel est l'objectif de ce site ! Nous voulons aider les professionnels sur les démarches à suivre pour demeurer dans le respect des lois.",
  image: "https://source.unsplash.com/random",
  imageText: "main image description",
};

const featuredPosts = [
  {
    title: "La Plateforme OPERAT",
    date: "20 août 2021",
    description:
      "L’Observatoire de la Performance Énergétique de la Rénovation et des Actions du Tertiaire (OPERAT) est la plateforme numérique de recueil et de suivi des consommations énergétiques du secteur tertiaire. Sa mise en place est prévue par le décret tertiaire et doit servir comme outil de supervision au service des objectifs fixés par le décret.",
    link: "https://a-grid.com/blog/operat/",
  },
  {
    title: "Le décret BACS",
    date: "22 juillet 2021",
    description:
      "BACS est l’acronyme de « Building Automation & Control System ». Le décret « BACS » fait référence au décret paru le 20 juillet 2020 « relatif au système d’automatisation et de contrôle des bâtiments non résidentiels et à la régulation auto...",
    link: "https://a-grid.com/blog/decret-bacs",
  },
];

// const posts = [post1, post2, post3];
const posts = [];

const sidebar = {
  title: "A propos",
  description:
    "Agrid est une entreprise d'efficacité énergétique, qui permet de faire économiser jusqu'à 25% des dépenses d'électicité sur la climatisation et le chauffage",

  social: [
    {
      name: "Twitter",
      icon: TwitterIcon,
      link: "https://twitter.com/agriddotcom",
    },
    {
      name: "Facebook",
      icon: FacebookIcon,
      link: "https://www.facebook.com/agriddotcom",
    },
    {
      name: "Linkedin",
      icon: LinkedIn,
      link: "https://www.linkedin.com/company/a-grid",
    },
  ],
};

const theme = createTheme({
  palette: {
    primary: {
      main: blueMedium,
    },
    secondary: {
      main: blueDeep,
    },
    info: {
      main: orange,
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: ["Arial"].join(","),
  },
});

export default function Blog() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Header title="Blog" />
      <Container maxWidth="lg" style={{ paddingTop: 80 }}>
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />

          <Grid container spacing={5} sx={{ mt: 3, marginTop: 0 }}>
            <Main title="From the firehose" />
            {/* <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            /> */}
          </Grid>
          {/* <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid> */}
          <br />
        </main>
      </Container>
      <Footer description="Be present, think about your future" />
    </ThemeProvider>
  );
}
